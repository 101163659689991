
/**
 * @name: handleCutZero
 * @description:  去掉double类型小数点后面多余的0
 参数：old 要处理的字符串或double
 返回值：newStr 没有多余零的小数或字符串
 * @param {*} num
 * @return {*}num
 */
export function parseFloatDesc(num) {
    num = Number(num)
    return parseFloat(num);
}