import api from './request'
import store from '@/store'

/**
 * get 请求
 * @param path
 * @returns {Promise}
 */
export const getData = async (path, params = {}, apiPrefix = 'api/') => {
    const res = await api.get(apiPrefix + path, params)
    if (res.code == 200) {
        return res.data
    } else {
        console.log(`${path}返回数据异常！`)
    }
}
/**
 * post 请求
 * @param path
 * @param params
 * @returns {Promise}
 */
export const postData = async (path, params = {}, apiPrefix = 'api/') => {
    const res = await api.post(apiPrefix + path, params)
    const data = res.data
    if (data.code == 200) {
        return data.data
    } else {
        console.log(`${path}返回数据异常！`)
    }
}


export const getHkCameraToken = () => {
    return api.get('/HkCamera/getHkCameraToken')
}
// export const getHkCameraAddress = (id) => {
//     return req.get('/HkCamera/getHkCameraAddress/'+id)
// }
// export const getFarmTopicByFarmId = () => {
//     return req.get('/xinbei/getFarmTopicByFarmId')
// }
export const upload = (formdata) => {
    return api.post('/upload?cameraId='+formdata.get('cameraId'),formdata, {
        // 表示上传的是文件,而不是普通的表单数据
        'Content-Type': 'multipart/form-data'
    })
}

/**
 * 校验登录态
 * 如果非登录态,则返回false,否则返回true
 */
export const checkLogin =  async () => {
    const res = await api.post('/login/checkLogin', {
        token: localStorage.getItem('token')
    })
    if (res.status == 200) {
        const data = res.data
        if (data.code != 200 || data.data.plat != 1) {
            return false
        }
        return true
    } else {
        return false
    }
}

